<template>
  <div>
    <the-toolbar title="Propostas PME">
      <v-btn icon @click="handleReload">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <v-btn icon @click="openFiltro">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </the-toolbar>

    <v-tabs v-model="tab" background-color="#fff" color="primary" grow @change="handleContentTabs">
      <v-tab :class="{ 'text-capitalize': true, 'tab-size': $vuetify.breakpoint.xs }">Em Andamento</v-tab>
      <v-tab :class="{ 'text-capitalize': true, 'tab-size': $vuetify.breakpoint.xs }">Em aprovação</v-tab>
      <v-tab :class="{ 'text-capitalize': true, 'tab-size': $vuetify.breakpoint.xs }">Histórico</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <div v-if="tab === 2">
      <v-tabs background-color="#fff" color="primary" v-model="tabThreeActive" grow @change="getContentTabThree">
        <v-tab v-for="(tab, index) in tabsThree" :class="{ 'text-capitalize': true, 'tab-size': $vuetify.breakpoint.xs }" :key="index">
          {{ tab.name }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>
    <div v-if="tab === 1">
      <v-tabs background-color="#fff" color="primary" v-model="tabTwoActive" grow @change="getContentTabTwo">
        <v-tab v-for="(tab, index) in tabsTwo" :class="{ 'text-capitalize': true, 'tab-size': $vuetify.breakpoint.xs }" :key="index">
            {{ tab.name }}
          <v-badge
          style="margin-left: 5px;"
            v-if="tab.name === 'Devolvida'"
             :color="notification > 0
              ? 'warning'
              : 'transparent'
          "
            :content="notification"
          ></v-badge>
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>
    <dialog-filtro-propostas :tab="tabRef[tab]" :dialog="dialogFiltro" @filtrarpropostas="filtrarPropostas" @close="dialogFiltro = false"></dialog-filtro-propostas>
    <v-tabs-items v-model="tab" class="pb-10" @change="handleContentTabs">
      <v-tab-item>
        <div v-if="loadingTabOne" class="d-flex justify-center mt-10 pa-10">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </div>
        <v-container class="pa-5 pt-2" v-else>
          <v-row no-gutters>
            <v-col>
              <p class="text-center ma-2 subtitle-2 grey--text">{{ totalPagination.andamento }} registro(s) encontrados(s)</p>
            </v-col>
          </v-row>
          <v-row v-if="contentTabOne.length">
            <v-col cols="12" md="6" xl="4" v-for="proposta in contentTabOne" :key="proposta.id">
              <div @click="getDatas(proposta.guid)" class="cursor-pointer">
                <base-card-details-proposta
                  :numero="proposta.cnpj || '--'"
                  :nome="proposta.razaoSocial ? proposta.razaoSocial : ''"
                  :operadora="proposta.operadora || '--'"
                  :beneficiarios="proposta.totalVidas"
                  :status="proposta.status"
                  :etapa="proposta.etapa"
                  :tempoParaTransmissao="{}"
                  :permitidoTransmissao="false"
                  :valor="proposta.valorContrato"
                  administradora="Qualicorp"
                ></base-card-details-proposta>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <div v-if="loadingTabTwo" class="d-flex justify-center mt-10 pa-10">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </div>
        <v-container class="pa-5 pt-2" v-else>
          <v-row no-gutters>
            <v-col>
              <p class="text-center ma-2 subtitle-2 grey--text">{{ totalPagination.aprovacao }} registro(s) encontrados(s)</p>
            </v-col>
          </v-row>
          <v-row v-if="contentTabTwo.length">
            <v-col cols="12" md="6" xl="4" v-for="proposta in contentTabTwo" :key="proposta.id">
              <div @click="getDatas(proposta.guid)" class="cursor-pointer">
                <base-card-details-proposta
                  :numero="proposta.cnpj || '--'"
                  :nome="proposta.razaoSocial ? proposta.razaoSocial : ''"
                  :operadora="proposta.operadora"
                  :beneficiarios="proposta.totalVidas"
                  :status="proposta.status"
                  :etapa="proposta.etapa"
                  :tempoParaTransmissao="{}"
                  :permitidoTransmissao="false"
                  :valor="proposta.valorContrato"
                  administradora="Qualicorp"
                ></base-card-details-proposta>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <div v-if="loadingTabThree" class="d-flex justify-center mt-10 pa-10">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </div>
        <v-container class="pa-5 pt-2" v-else>
          <v-row no-gutters>
            <v-col>
              <p class="text-center ma-2 subtitle-2 grey--text">{{ totalPagination.historico }} registro(s) encontrados(s)</p>
            </v-col>
          </v-row>
          <v-row v-if="contentTabThree.length">
            <v-col cols="12" md="6" xl="4" v-for="proposta in contentTabThree" :key="proposta.id">
              <div @click="getDatas(proposta.guid)" class="cursor-pointer">
                <base-card-details-proposta
                  :numero="proposta.cnpj || '--'"
                  :nome="proposta.razaoSocial ? proposta.razaoSocial : ''"
                  :operadora="proposta.operadora"
                  :beneficiarios="proposta.totalVidas"
                  :status="proposta.status"
                  :etapa="proposta.etapa"
                  :tempoParaTransmissao="{}"
                  :permitidoTransmissao="false"
                  :valor="proposta.valorContrato"
                  administradora="Qualicorp"
                ></base-card-details-proposta>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <v-pagination
      v-if="quantidadePaginas"
      v-model="pagePagination"
      :length="quantidadePaginas"
      rounded="circle"
      class="mb-5"
    ></v-pagination>
    <v-btn :color="$store.getters.getColor('primary')" fixed dark right bottom fab class="mt-10" style="bottom: 10px" @click="redirecionarCriaProposta">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import propostaPMEService from '@/services/propostaPMEService';
import BaseCardDetailsProposta from '@/components/BaseCardDetailsPropostaPME.vue';
import TheToolbar from '@/components/TheToolbar.vue';
import { format, parse } from 'date-fns';
import DialogFiltroPropostas from './DialogFiltroPropostas.vue';

export default {
  name: 'PropostasPME',

  components: { TheToolbar, BaseCardDetailsProposta, DialogFiltroPropostas },
  data() {
    return {
      dialogFiltro: false,
      tab: null,
      loadingTabOne: false,
      loadingTabTwo: false,
      loadingTabThree: false,
      tabThreeActive: 0,
      tabTwoActive: 0,
      tabsTwo: [
        {
          id: ['EM_ANALISE', 'FILA_TRANSMISSAO'],
          name: 'Em Analise',
        },
        {
          id: ['DEVOLVIDA'],
          name: 'Devolvida',
        },
      ],
      tabsThree: [
        {
          id: ['IMPLANTADO', 'IMPLANTADA', 'FINALIZADA'],
          name: 'Implantado',
        },
        {
          id: ['CANCELADO', 'CANCELADA'],
          name: 'Cancelado',
        },
        {
          id: ['NAO_RECOMENDADA', 'CANCELADA_PRE_VIGENCIA'],
          name: 'Não Implantada',
        }
      ],
      tabsContent: [],
      tabRef: ['em_andamento', 'em_aprovacao', 'historico'],
      contentTabOne: [],
      contentTabTwo: [],
      contentTabThree: [],
      notification: 0,
      pagePagination: 1,
      totalPagination: {
        andamento: 0,
        aprovacao: 0,
        historico: 0
      },
    };
  },
  watch: {
    pagePagination(val) {
      this.handleReload(val)
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
    }),
    quantidadePaginas() {
      const deParaTab = {
        0: "andamento",
        1: "aprovacao",
        2: "historico"
      }
      const totalPagination = this.totalPagination[deParaTab[this.tab]];
      if (!totalPagination) return 0;
      if (totalPagination > 10) return Math.ceil(totalPagination / 10);
      return 1
    }
  },
  methods: {
    ...mapActions({
      setBlnContinuar: 'cadastroProposta/setBlnContinuar',
      resetDadosProposta: 'cadastroProposta/resetDadosProposta'
    }),
    redirecionarCriaProposta() {
      this.resetDadosProposta()
      this.setBlnContinuar({ flag: false, page: 0 });
      this.$router.push({ name: 'areaLogada.PME' });
    },
    filtrarPropostas(filtros) {
      this.dialogFiltro = false;

      const keysFiltros = Object.keys(filtros.campos);
      const objFiltros = {};
      keysFiltros.forEach((key) => {
        if (filtros.campos[key]) {
          if (key.includes('data')) {
            objFiltros[key] = format(parse(filtros.campos[key], 'dd/mm/yyyy', new Date()), 'yyyy-mm-dd');
          } else {
            objFiltros[key] = filtros.campos[key];
          }
        }
      });

      this.filtros = objFiltros;
      try {
        this.handleContentTabs(this.tab, true /** true = force reload */);
      } catch (err) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao buscar dados, tente novamente mais tarde',
        });
      }
    },
    getDatas(guid) {
      this.$router.push({ name: 'areaLogada.resumoPropostaPME', params: { guid } });
    },
    async getContentTabOne() {
      try {
        this.loadingTabOne = true;
        const data = await propostaPMEService.get({filtros: this.filtros, statusAgrupado: 'em_andamento', page: this.pagePagination, limit: 10});
        this.totalPagination.andamento = data.total
        this.contentTabOne = this.orderByChangeDate(data.propostasPME);
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao buscar dados, tente novamente mais tarde',
        });
      } finally {
        this.loadingTabOne = false;
      }
    },
    async getContentTabTwo() {
      try {
        this.loadingTabTwo = true;
        let data = await propostaPMEService.get({filtros: this.filtros, statusAgrupado: 'em_aprovacao', page: this.pagePagination, limit: 10});
        this.totalPagination.aprovacao = data.total;
        this.notification = 0;
        // eslint-disable-next-line consistent-return
        data.propostasPME.forEach((e) => {
          if (e.status === "DEVOLVIDA") {
            return this.notification++;
          }
        });
        data = data.propostasPME.filter((d) => this.tabsTwo[this.tabTwoActive].id.includes(d.status));
        this.contentTabTwo = this.orderByChangeDate(data);
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao buscar dados, tente novamente mais tarde',
        });
      } finally {
        this.loadingTabTwo = false;
      }
    },
    async getContentTabThree() {
      try {
        this.loadingTabThree = true;
        let data = await propostaPMEService.get({filtros: this.filtros, statusAgrupado: 'historico', page: this.pagePagination, limit: 10});
        this.totalPagination.historico = data.total;
        data = data.propostasPME.filter((d) => this.tabsThree[this.tabThreeActive].id.includes(d.status));
        this.contentTabThree = this.orderByChangeDate(data);
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao buscar dados, tente novamente mais tarde',
        });
      } finally {
        this.loadingTabThree = false;
      }
    },
    async handleReload(page) {
      switch (this.tab) {
      case 0: {
        await this.getContentTabOne(page);
        break;
      }
      case 1: {
        await this.getContentTabTwo(page);
        break;
      }
      case 2: {
        await this.getContentTabThree(page);
        break;
      }
      default: {
        break;
      }
      }
    },
    async handleContentTabs(value, reloadFilter) {
      this.pagePagination = 1;
      switch (value) {
      case 0: {
        if (!this.contentTabOne.length || reloadFilter) {
          await this.getContentTabOne();
        }
        break;
      }
      case 1: {
        if (!this.contentTabTwo.length || reloadFilter) {
          await this.getContentTabTwo();
        }
        break;
      }
      case 2: {
        if (!this.contentTabThree.length || reloadFilter) {
          await this.getContentTabThree();
        }
        break;
      }
      default: {
        break;
      }
      }
    },
    openFiltro() {
      this.dialogFiltro = true;
    },
    orderByChangeDate(array) {
      array.sort((a, b) => new Date(b.dataCriacao) - new Date(a.dataCriacao));
      return array;
    },
    associadoTratando(usuarioTratando) {
      if (usuarioTratando && usuarioTratando.id && usuarioTratando.id !== this.user.id) {
        return usuarioTratando.nome;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.tab-size {
  font-size: 0.8rem;
}
</style>

