var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-card-details", {
    attrs: { title: _vm.nome || "Não definido" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "v-list-item-avatar",
              { attrs: { dark: "", color: "grey", size: "34" } },
              [
                _c("span", { staticClass: "white--text subtitle-1 mt-1" }, [
                  _vm._v(_vm._s(_vm._f("avatarName")(_vm.nome))),
                ]),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c("v-col", { staticClass: "mb-4", attrs: { cols: "4" } }, [
                  _c("div", [
                    _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                      _vm._v("CNPJ"),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("cnpjCpfMask")(_vm.numero))),
                    ]),
                  ]),
                ]),
                _c("v-col", { staticClass: "mb-4", attrs: { cols: "8" } }, [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end" },
                    [
                      _vm.envioBoleto
                        ? _c(
                            "v-chip",
                            {
                              staticClass: "ma-2",
                              attrs: {
                                color: "primary",
                                small: "",
                                dark: "",
                                label: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onClickEnviarBoleto()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "mt-1 text-uppercase" },
                                [_vm._v(" Enviar Boleto ")]
                              ),
                            ]
                          )
                        : _c(
                            "v-chip",
                            {
                              staticClass: "ma-2",
                              attrs: {
                                color: "primary",
                                small: "",
                                dark: "",
                                label: "",
                              },
                            },
                            [
                              _vm.etapa && _vm.etapa
                                ? _c(
                                    "span",
                                    { staticClass: "mt-1 text-uppercase" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("etapaAdesaoPme")(_vm.etapa)
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                      _vm.getSubTitle().toUpperCase() === "IMPLANTADA" &&
                      ((_vm.administradora &&
                        _vm.administradora.toUpperCase() === "QUALICORP") ||
                        _vm.administradora.toUpperCase() === "ELO" ||
                        _vm.administradora.toUpperCase() === "ALIANÇA")
                        ? _c(
                            "span",
                            {
                              staticClass: "tooltip ma-1",
                              attrs: { small: "" },
                            },
                            [
                              _c("v-icon", { attrs: { size: "30" } }, [
                                _vm._v("mdi-alert-circle-outline"),
                              ]),
                              _c("span", { staticClass: "tooltip-text" }, [
                                _vm._v(
                                  "A proposta ainda será analisada pela operadora, por favor, aguarde o status "
                                ),
                                _c("b", [_vm._v("Finalizado")]),
                                _vm._v(" para notificar seu cliente."),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c(
                  "v-col",
                  { staticClass: "mb-4", attrs: { cols: "4", md: "6" } },
                  [
                    _c("div", [
                      _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                        _vm._v("Operadora"),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.operadora))]),
                    ]),
                  ]
                ),
                _c(
                  "v-col",
                  { staticClass: "mb-4", attrs: { cols: "8", md: "6" } },
                  [
                    _c("div", [
                      _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                        _vm._v("Quantidade de vidas"),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.beneficiarios))]),
                    ]),
                  ]
                ),
                _vm.envioBoleto && _vm.corretora
                  ? _c(
                      "v-col",
                      { staticClass: "mb-4", attrs: { cols: "6", md: "8" } },
                      [
                        _c("div", [
                          _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                            _vm._v(" Corretora "),
                          ]),
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.corretora) + " "),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "v-col",
                  { staticClass: "mb-4", attrs: { cols: "6", md: "8" } },
                  [
                    _c("div", [
                      _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                        _vm._v("Status da Proposta"),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.status === "EM_DIGITACAO"
                              ? "EM DIGITAÇÃO"
                              : "--"
                          )
                        ),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c("v-spacer"),
            _c(
              "span",
              { staticClass: "subtitle-1 font-weight-bold primary--text" },
              [_vm._v(_vm._s(_vm._f("currency")(_vm.valor)))]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }