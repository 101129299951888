<template>
  <v-dialog no-click-animation v-model="dialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition" eager>
    <v-card>
      <v-container class="pa-5 pt-0">
        <v-row justify="center">
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-row>
              <v-col class="pb-0">
                <div>
                  <the-nav-bar title="Filtrar Propostas" @back="close"></the-nav-bar>
                  <div class="header-height"></div>
                  <v-container class="pa-5 pt-5">
                    <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
                      <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
                    </div>
                    <v-row v-else>
                      <v-col cols="12">
                        <v-row justify="center">
                          <v-col cols="12">
                            <validation-observer ref="form" @submit.prevent="submit()" tag="form">
                              <base-text-field
                                validationMode="aggressive"
                                id="nomeEmpresaNrContrato"
                                name="Nome Empresa ou Nº Contrato"
                                v-model="form.nomeEmpresaNrContrato"
                                label="Nome Empresa ou Nº Contrato"
                                clearable
                                outlined
                              ></base-text-field>

                              <base-text-field
                                validationMode="aggressive"
                                id="nomeTitularNrProposta"
                                name="Nome Beneficiário Titular ou Nº Proposta"
                                v-model="form.nomeTitularNrProposta"
                                label="Nome Beneficiário Titular ou Nº Proposta"
                                clearable
                                outlined
                              ></base-text-field>

                              <base-wrapper-dialog-options :key="1" headerTitle="Status" :options="dataStatus[tab]" @select="setStatus" value-key="nome" label-key="nome">
                                <template v-slot:activador="{ on }">
                                  <base-text-field
                                    :loading="false"
                                    readonly
                                    id="etapa"
                                    placeholder="Clique aqui para selecionar"
                                    name="Status"
                                    v-on="on"
                                    :value="form.etapa"
                                    label="Status"
                                    outlined
                                    append-icon="mdi-chevron-right"
                                  ></base-text-field>
                                </template>
                              </base-wrapper-dialog-options>

                              <v-row>
                                <v-col cols="12">
                                  <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Data Proposta</h2>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="6" lg="6" xl="6">
                                  <validation-provider v-slot="{ errors }" name="dataInicio" vid="dataInicio">
                                    <base-text-field
                                      :error-messages="errors"
                                      @blur="debouncedValidateDataVigencia"
                                      :value="form.dataInicio"
                                      rules="realDate"
                                      id="dataInicio"
                                      name="Data de Inicio Vigência"
                                      label="Data de Inicio Vigência"
                                      placeholder="DD/MM/AAAA"
                                      v-mask="'##/##/####'"
                                      inputmode="numeric"
                                      clearable
                                      v-model="form.dataInicio"
                                      outlined
                                    ></base-text-field>
                                  </validation-provider>
                                </v-col>
                                <v-col cols="12" md="6" lg="6" xl="6">
                                  <validation-provider v-slot="{ errors }" name="dataFim" vid="dataFim">
                                    <base-text-field
                                      @blur="debouncedValidateDataVigencia"
                                      :error-messages="errors"
                                      :value="form.dataFim"
                                      rules="requiredif:dataInicio|realDate"
                                      id="dataFim"
                                      name="Data de Fim Vigência"
                                      label="Data de Fim Vigência"
                                      placeholder="DD/MM/AAAA"
                                      v-mask="'##/##/####'"
                                      inputmode="numeric"
                                      clearable
                                      v-model="form.dataFim"
                                      outlined
                                    ></base-text-field>
                                  </validation-provider>
                                </v-col>
                              </v-row>
                              <v-btn large type="submit" elevation="10" block color="primary" class="text-none mt-5 mb-4">Filtrar</v-btn>
                              <v-btn large type="submit" elevation="10" block color="secondary" class="text-none mt-5 mb-4" @click.prevent="limparFiltros">Limpar Filtro</v-btn>
                            </validation-observer>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseWrapperDialogOptions from "@/components/BaseWrapperDialogOptions.vue";
import BaseWrapperDialogList from "@/components/BaseWrapperDialogList.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import TheNavBar from "@/components/TheNavBar.vue";

const _ = require("lodash");

export default {
  name: "DialogFiltroPropostas",
  components: {
    TheNavBar,
    BaseTextField,
    BaseWrapperDialogOptions,
    BaseWrapperDialogList,
  },
  data() {
    return {
      loading: false,
      form: {},
      dataStatus: {
        em_andamento: [
          {
            idsEtapa: [
              "basicos",
              "corretora",
              "operadora",
              "plano",
              "vigencia",
              "dados_titular_contratacao",
              "dados_responsavel_contratacao",
              "dados_profissionais",
              "dependentes_contratacao",
              "produto_opcional",
              "dados_financeiros",
              "anexo",
              "preenchido",
            ],
            status: "EM_DIGITACAO",
            nome: "Em Digitação",
          },
          {
            idsEtapa: ["aguardando_assinatura"],
            status: "EM_DIGITACAO",
            nome: "Aguardando Assinatura",
          },
          {
            idsEtapa: ["assinatura_recusada"],
            status: "EM_DIGITACAO",
            nome: "Assinatura Recusada",
          },
          {
            idsEtapa: ["contrato_assinado"],
            status: "EM_DIGITACAO",
            nome: "Contrato Assinado",
          },
          {
            idsEtapa: ["pronta_transmissao"],
            status: "EM_DIGITACAO",
            nome: "Pronta Transmissão",
          },
        ],
        em_aprovacao: [
          {
            idsEtapa: ["devolvida", "aguardando_assinatura_adendo", "adendo_assinado", "adendo_recusado"],
            status: "DEVOLVIDA",
            nome: "Devolvida",
          },
          {
            idsEtapa: ["em_analise"],
            status: "EM_ANALISE",
            nome: "Em análise",
          },
          {
            idsEtapa: ["fila_transmissao", "erro_transmissao"],
            status: "FILA_TRANSMISSAO",
            nome: "Fila Transmissão",
          },
        ],
        historico: [
          {
            idsEtapa: ["cancelada"],
            status: "CANCELADA",
            nome: "Cancelada",
          },
          {
            idsEtapa: ["implantada"],
            status: "IMPLANTADA",
            nome: "Implantada",
          },
          {
            idsEtapa: ["finalizada"],
            status: "FINALIZADA",
            nome: "Finalizada",
          },
          {
            idsEtapa: ["nao_recomendada"],
            status: "NAO_RECOMENDADA",
            nome: "Não Recomendada",
          },
          {
            idsEtapa: ["finalizada"],
            status: "FINALIZADA",
            nome: "Finalizada",
          },
        ],
      },
    };
  },
  props: {
    tab: {
      default: "em_andamento",
    },
    dialog: {
      default: false,
    },
  },
  created() {
    this.debouncedValidateDataVigencia = _.debounce(this.validateDataVigencia, 350);
  },
  computed: {},
  methods: {
    validateDataVigencia() {
      const dataInicio = this.form.dataInicio && this.form.dataInicio.split("/");
      const dataFim = this.form.dataFim && this.form.dataFim.split("/");

      if (this.$refs.form.errors.dataInicio && !this.$refs.form.errors.dataInicio.length && this.$refs.form.errors.dataFim && !this.$refs.form.errors.dataFim.length) {
        let dataInicioDate;
        let dataFimDate;

        if (dataInicio && dataInicio.length === 3) dataInicioDate = new Date(dataInicio[2], parseInt(dataInicio[1], 10) - 1, dataInicio[0]);
        if (dataFim && dataFim.length === 3) dataFimDate = new Date(dataFim[2], parseInt(dataFim[1], 10) - 1, dataFim[0]);

        if (typeof dataInicioDate === "object") dataInicioDate = dataInicioDate.getTime();
        if (typeof dataFimDate === "object") dataFimDate = dataFimDate.getTime();

        if (dataInicioDate && dataFimDate && dataInicioDate > dataFimDate) {
          this.$root.$snackBar.open({
            color: "error",
            message: "Data de inicio de vigência não pode ser maior que o fim",
          });

          this.form.dataInicio = "";
          this.form.dataFim = "";
        }
      }
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.isLoading = true;
      try {
        const campos = {};
        Object.assign(campos, this.form);

        let etapa = {};

        if (campos.etapa) etapa = this.dataStatus[this.tab].find((el) => el.nome === campos.etapa) || {};

        if (etapa.idsEtapa) campos.etapa = { idsEtapa: etapa.idsEtapa };

        this.$emit("filtrarpropostas", { campos });
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
      } finally {
        this.isLoading = false;
      }
    },
    setStatus(event) {
      this.$set(this.form, "etapa", event.nome);
    },
    limparFiltros() {
      this.form = {};
    },
    close() {
      this.$emit("close", false);
    },
  },
};
</script>
