var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "the-toolbar",
        { attrs: { title: "Propostas PME" } },
        [
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.handleReload } },
            [_c("v-icon", [_vm._v("mdi-reload")])],
            1
          ),
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.openFiltro } },
            [_c("v-icon", [_vm._v("mdi-magnify")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-tabs",
        {
          attrs: { "background-color": "#fff", color: "primary", grow: "" },
          on: { change: _vm.handleContentTabs },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab",
            {
              class: {
                "text-capitalize": true,
                "tab-size": _vm.$vuetify.breakpoint.xs,
              },
            },
            [_vm._v("Em Andamento")]
          ),
          _c(
            "v-tab",
            {
              class: {
                "text-capitalize": true,
                "tab-size": _vm.$vuetify.breakpoint.xs,
              },
            },
            [_vm._v("Em aprovação")]
          ),
          _c(
            "v-tab",
            {
              class: {
                "text-capitalize": true,
                "tab-size": _vm.$vuetify.breakpoint.xs,
              },
            },
            [_vm._v("Histórico")]
          ),
        ],
        1
      ),
      _c("v-divider"),
      _vm.tab === 2
        ? _c(
            "div",
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    "background-color": "#fff",
                    color: "primary",
                    grow: "",
                  },
                  on: { change: _vm.getContentTabThree },
                  model: {
                    value: _vm.tabThreeActive,
                    callback: function ($$v) {
                      _vm.tabThreeActive = $$v
                    },
                    expression: "tabThreeActive",
                  },
                },
                _vm._l(_vm.tabsThree, function (tab, index) {
                  return _c(
                    "v-tab",
                    {
                      key: index,
                      class: {
                        "text-capitalize": true,
                        "tab-size": _vm.$vuetify.breakpoint.xs,
                      },
                    },
                    [_vm._v(" " + _vm._s(tab.name) + " ")]
                  )
                }),
                1
              ),
              _c("v-divider"),
            ],
            1
          )
        : _vm._e(),
      _vm.tab === 1
        ? _c(
            "div",
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    "background-color": "#fff",
                    color: "primary",
                    grow: "",
                  },
                  on: { change: _vm.getContentTabTwo },
                  model: {
                    value: _vm.tabTwoActive,
                    callback: function ($$v) {
                      _vm.tabTwoActive = $$v
                    },
                    expression: "tabTwoActive",
                  },
                },
                _vm._l(_vm.tabsTwo, function (tab, index) {
                  return _c(
                    "v-tab",
                    {
                      key: index,
                      class: {
                        "text-capitalize": true,
                        "tab-size": _vm.$vuetify.breakpoint.xs,
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(tab.name) + " "),
                      tab.name === "Devolvida"
                        ? _c("v-badge", {
                            staticStyle: { "margin-left": "5px" },
                            attrs: {
                              color:
                                _vm.notification > 0
                                  ? "warning"
                                  : "transparent",
                              content: _vm.notification,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
              _c("v-divider"),
            ],
            1
          )
        : _vm._e(),
      _c("dialog-filtro-propostas", {
        attrs: { tab: _vm.tabRef[_vm.tab], dialog: _vm.dialogFiltro },
        on: {
          filtrarpropostas: _vm.filtrarPropostas,
          close: function ($event) {
            _vm.dialogFiltro = false
          },
        },
      }),
      _c(
        "v-tabs-items",
        {
          staticClass: "pb-10",
          on: { change: _vm.handleContentTabs },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            [
              _vm.loadingTabOne
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center mt-10 pa-10" },
                    [
                      _c("v-progress-circular", {
                        attrs: { color: "primary", indeterminate: "" },
                      }),
                    ],
                    1
                  )
                : _c(
                    "v-container",
                    { staticClass: "pa-5 pt-2" },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-center ma-2 subtitle-2 grey--text",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.totalPagination.andamento) +
                                    " registro(s) encontrados(s)"
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm.contentTabOne.length
                        ? _c(
                            "v-row",
                            _vm._l(_vm.contentTabOne, function (proposta) {
                              return _c(
                                "v-col",
                                {
                                  key: proposta.id,
                                  attrs: { cols: "12", md: "6", xl: "4" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.getDatas(proposta.guid)
                                        },
                                      },
                                    },
                                    [
                                      _c("base-card-details-proposta", {
                                        attrs: {
                                          numero: proposta.cnpj || "--",
                                          nome: proposta.razaoSocial
                                            ? proposta.razaoSocial
                                            : "",
                                          operadora: proposta.operadora || "--",
                                          beneficiarios: proposta.totalVidas,
                                          status: proposta.status,
                                          etapa: proposta.etapa,
                                          tempoParaTransmissao: {},
                                          permitidoTransmissao: false,
                                          valor: proposta.valorContrato,
                                          administradora: "Qualicorp",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _vm.loadingTabTwo
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center mt-10 pa-10" },
                    [
                      _c("v-progress-circular", {
                        attrs: { color: "primary", indeterminate: "" },
                      }),
                    ],
                    1
                  )
                : _c(
                    "v-container",
                    { staticClass: "pa-5 pt-2" },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-center ma-2 subtitle-2 grey--text",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.totalPagination.aprovacao) +
                                    " registro(s) encontrados(s)"
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm.contentTabTwo.length
                        ? _c(
                            "v-row",
                            _vm._l(_vm.contentTabTwo, function (proposta) {
                              return _c(
                                "v-col",
                                {
                                  key: proposta.id,
                                  attrs: { cols: "12", md: "6", xl: "4" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.getDatas(proposta.guid)
                                        },
                                      },
                                    },
                                    [
                                      _c("base-card-details-proposta", {
                                        attrs: {
                                          numero: proposta.cnpj || "--",
                                          nome: proposta.razaoSocial
                                            ? proposta.razaoSocial
                                            : "",
                                          operadora: proposta.operadora,
                                          beneficiarios: proposta.totalVidas,
                                          status: proposta.status,
                                          etapa: proposta.etapa,
                                          tempoParaTransmissao: {},
                                          permitidoTransmissao: false,
                                          valor: proposta.valorContrato,
                                          administradora: "Qualicorp",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _vm.loadingTabThree
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center mt-10 pa-10" },
                    [
                      _c("v-progress-circular", {
                        attrs: { color: "primary", indeterminate: "" },
                      }),
                    ],
                    1
                  )
                : _c(
                    "v-container",
                    { staticClass: "pa-5 pt-2" },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-center ma-2 subtitle-2 grey--text",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.totalPagination.historico) +
                                    " registro(s) encontrados(s)"
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm.contentTabThree.length
                        ? _c(
                            "v-row",
                            _vm._l(_vm.contentTabThree, function (proposta) {
                              return _c(
                                "v-col",
                                {
                                  key: proposta.id,
                                  attrs: { cols: "12", md: "6", xl: "4" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.getDatas(proposta.guid)
                                        },
                                      },
                                    },
                                    [
                                      _c("base-card-details-proposta", {
                                        attrs: {
                                          numero: proposta.cnpj || "--",
                                          nome: proposta.razaoSocial
                                            ? proposta.razaoSocial
                                            : "",
                                          operadora: proposta.operadora,
                                          beneficiarios: proposta.totalVidas,
                                          status: proposta.status,
                                          etapa: proposta.etapa,
                                          tempoParaTransmissao: {},
                                          permitidoTransmissao: false,
                                          valor: proposta.valorContrato,
                                          administradora: "Qualicorp",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.quantidadePaginas
        ? _c("v-pagination", {
            staticClass: "mb-5",
            attrs: { length: _vm.quantidadePaginas, rounded: "circle" },
            model: {
              value: _vm.pagePagination,
              callback: function ($$v) {
                _vm.pagePagination = $$v
              },
              expression: "pagePagination",
            },
          })
        : _vm._e(),
      _c(
        "v-btn",
        {
          staticClass: "mt-10",
          staticStyle: { bottom: "10px" },
          attrs: {
            color: _vm.$store.getters.getColor("primary"),
            fixed: "",
            dark: "",
            right: "",
            bottom: "",
            fab: "",
          },
          on: { click: _vm.redirecionarCriaProposta },
        },
        [_c("v-icon", [_vm._v("mdi-plus")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }