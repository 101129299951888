<template>
  <base-card-details :title="nome || 'Não definido'">
    <template v-slot:header>
      <v-list-item-avatar dark color="grey" size="34">
        <span class="white--text subtitle-1 mt-1">{{nome | avatarName}}</span>
      </v-list-item-avatar>
    </template>
    <template v-slot:body>
       <v-row no-gutters>
        <v-col cols="4" class="mb-4">
          <div>
            <h3 class="mb-1 subtitle-2">CNPJ</h3>
            <span>{{numero | cnpjCpfMask}}</span>
          </div>
        </v-col>
        <v-col cols="8" class="mb-4">
          <div class="d-flex justify-end">
            <v-chip v-if="envioBoleto" color="primary" small class="ma-2" dark label @click="onClickEnviarBoleto()">
              <span class="mt-1 text-uppercase">
                Enviar Boleto
              </span>
            </v-chip>
            <v-chip v-else color="primary" small class="ma-2" dark label>
              <span v-if="etapa && etapa" class="mt-1 text-uppercase">{{ etapa | etapaAdesaoPme }}</span>
            </v-chip>
            <span v-if=" getSubTitle().toUpperCase() === 'IMPLANTADA' &&
                  (administradora && administradora.toUpperCase() === 'QUALICORP' || administradora.toUpperCase() === 'ELO' || administradora.toUpperCase() === 'ALIANÇA')" class="tooltip ma-1" small><v-icon size="30">mdi-alert-circle-outline</v-icon>
              <span class="tooltip-text">A proposta ainda será analisada pela operadora, por favor, aguarde o status <b>Finalizado</b> para notificar seu cliente.</span>
            </span>
          </div>
        </v-col>
        <v-col cols="4" md="6" class="mb-4">
          <div>
            <h3 class="mb-1 subtitle-2">Operadora</h3>
            <span>{{operadora}}</span>
          </div>
        </v-col>
        <v-col cols="8" md="6" class="mb-4">
          <div>
            <h3 class="mb-1 subtitle-2">Quantidade de vidas</h3>
            <span>{{beneficiarios}}</span>
          </div>
        </v-col>
        <v-col v-if="envioBoleto && corretora" cols="6" md="8" class="mb-4">
          <div>
            <h3 class="mb-1 subtitle-2">
              Corretora
            </h3>
            <span>
              {{ corretora }}
            </span>
          </div>
        </v-col>
        <v-col cols="6" md="8" class="mb-4">
          <div>
            <h3 class="mb-1 subtitle-2">Status da Proposta</h3>
            <span>{{ status === "EM_DIGITACAO" ? "EM DIGITAÇÃO" : "--" }}</span>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-slot:footer>
      <v-spacer></v-spacer>
      <span class="subtitle-1 font-weight-bold primary--text">{{ valor | currency }}</span>
    </template>
  </base-card-details>
</template>

<script>
import BaseCardDetails from '@/components/BaseCardDetails.vue';
import moment from 'moment';
import envioBoletoService from "@/services/envioBoletoService";

export default {
  data() {
    return {
      statusSemCronometro: ['em_analise', 'cancelada', 'implantada', 'finalizada'],
      boletos: [],
      boletosNaoEmitidos: [],
    };
  },
  components: {
    BaseCardDetails,
  },
  props: {
    nome: {
      type: String,
    },
    avatar: {
      type: String,
    },
    numero: {
      type: String,
      default: '--',
    },
    operadora: {
      type: String,
      default: '--',
    },
    administradora: {
      type: String,
      default: '--',
    },
    beneficiarios: {
      type: Number,
      default: '--',
    },
    status: {
      type: String,
      default: '--',
    },
    etapa: {
      type: String,
      default: '--',
    },
    tempoParaTransmissao: {
      type: Object,
      default: null,
    },
    valor: {
      type: Number,
      default: 0,
    },
    permitidoTransmissao: {
      type: Boolean,
    },
    associado: {
      type: String,
    },
    envioBoleto: {
      type: Boolean,
      default: false,
    },
    corretora: {
      type: String,
    },
    vigencia: {
      type: Object,
      default: () => {},
    },
    formaPagamento: {
      type: String,
    },
  },
  computed: {
    descricaoPermitidoTransmissao() {
      if (this.etapa && this.etapa.id === "em_analise") {
        return { mobile: '', responsivo: '' };
      }
      if (this.permitidoTransmissao && this.tempoParaTransmissao.tempoParaTransmissao) {
        return { mobile: `Tempo para transmitir <br> ${this.tempoParaTransmissao.tempoParaTransmissao}`, responsivo: `Tempo para transmitir  ${this.tempoParaTransmissao.tempoParaTransmissao}` };
      }
      if (this.permitidoTransmissao === false) {
        return { mobile: 'Fora do prazo de transmissão', responsivo: 'Fora do prazo de transmissão' };
      }
      return { mobile: '', responsivo: '' };
    },
    exibirCronometro() {
      return (this.permitidoTransmissao === true || this.permitidoTransmissao === false)
       && !this.statusSemCronometro.includes(this.etapa.id);
    },
    statusEmAnalise() {
      return this.etapa.id === 'em_analise';
    },
  },
  methods: {
    getSubTitle() {
      if (this.etapa != null && this.etapa.subtitulo != null) {
        if (this.etapa.id === 'em_analise') {
          if (this.etapa.contador > 0) return `${this.etapa.contador}ª transmissão`;
          return 'transmitido';
        } return this.etapa.subtitulo;
      }
      return 'Não definido';
    },
    formatData(data) {
      return moment(data).format('DD/MM/YYYY');
    },
    formatRefData(data) {
      return moment(data).format('MM/YYYY');
    },
    setDefaultBoletosObject() {
      this.boletos = [];
      this.boletosNaoEmitidos = [];
    },
    async onClickExpansionPanel(event) {
      if (!event.currentTarget.classList.contains("v-expansion-panel-header--active")) {
        try {
          this.$emit("isLoading", true);
          // Boletos emitidos
          const { data: boletos } = await envioBoletoService.buscarBoletos(this.numero);
          this.boletos = boletos;
          // Boletos não emitidos
          const boletosNaoEmitidos = [];
          if (this.boletos.length < 3) {
            for (let i = 0; i < 3 - this.boletos.length; i++) {
              boletosNaoEmitidos.push({
                status: "Não emitido.",
              });
            }
            this.boletosNaoEmitidos = boletosNaoEmitidos;
          }
          this.$emit("setBoletos", this.numero, boletos, this.boletosNaoEmitidos, this.formaPagamento);
        } catch (error) {
          this.$root.$snackBar.open({
            color: "error",
            message: "Erro ao buscar dados, tente novamente mais tarde",
          });
        } finally {
          this.$emit("isLoading", false);
        }
      }
    },
    async onClickEnviarBoleto() {
      try {
        this.$emit("isLoading", true);
        // Boletos emitidos
        const { data: boletos } = await envioBoletoService.buscarBoletos(this.numero);
        this.boletos = boletos;
        // Boletos não emitidos
        const boletosNaoEmitidos = [];
        if (this.boletos.length < 3) {
          for (let i = 0; i < 3 - this.boletos.length; i++) {
            boletosNaoEmitidos.push({
              status: "Não emitido.",
            });
          }
          this.boletosNaoEmitidos = boletosNaoEmitidos;
        }
        this.$emit("setBoletos", this.numero, boletos, this.boletosNaoEmitidos, this.formaPagamento);
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao buscar dados, tente novamente mais tarde",
        });
      } finally {
        this.$emit("isLoading", false);
      }
      this.$emit("onClickEnviarBoleto");
    },
    checkStatusBoleto(boleto) {
      let status;
      if (boleto.dataPagamento) status = `PAGO EM ${boleto.dataPagamento.substr(0, 10)}.`;
      else if (boleto.dataVencimento) status = `Vencimento em ${boleto.dataVencimento.substr(0, 10)}.`;
      else status = "Não emitido.";
      return status;
    },
    onClickCompartilhar(boleto) {
      this.$emit("onClickCompartilhar", boleto);
    },
  },
};
</script>

<style lang="scss">
#custom-expansion-panel-content .v-expansion-panel-content__wrap {
  padding-left: 0;
  padding-right: 0;
}
.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip .tooltip-text {
	visibility: hidden;
	background-color: #171717;
	color: #f8f5f4;
	border-radius: 8px;
	padding: 8px;
	position: absolute;
	width: 336px;
	transform: translate(-50%, 22%);
	z-index: 1;
	font-size: 14px;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.tooltip .tooltip-text::after {
	content: "";
	position: absolute;
	bottom: 100%;
	right: 50%;
	border-width: 8.66px;
	border-style: solid;
	border-color: transparent transparent #f8f5f4;
}

.tooltip:hover .tooltip-text,
.tooltip:focus .tooltip-text {
	visibility: visible;
}
</style>
